// DEPENDENCIES
import React, { ReactElement } from "react";
import Router from "next/router";
import { Button, IconFa, Heading } from "@cruk/cruk-react-components";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// COMPONENTS
import {
  ExtendedBoxWrapper,
  PageContainer,
  MaxWidth,
} from "../components/styles";
import DonationFormWrapper from "../components/single-donation/DonationFormWrapper";

// CONTEXTS
import FormNameContext from "../contexts/FormNameContext";
import PhoneNumber from "../components/PhoneNumber";

const ErrorPage = (): ReactElement => {
  const formPath =
    typeof window !== "undefined" ? window.location.pathname.split("/") : "";
  const formName = formPath ? formPath[1] : "support-us";

  return (
    <FormNameContext.Provider value={formName}>
      <DonationFormWrapper page="error">
        <PageContainer>
          <MaxWidth>
            <ExtendedBoxWrapper marginVertical="none" marginHorizontal="auto">
              <h1>404 - Page not found</h1>
              <Heading h2>
                Sorry - the page you are looking for can&rsquo;t be found
              </Heading>
              <p>Please check that you have entered the correct web address.</p>

              <Heading h3>Donate to Cancer Research UK</Heading>
              <p>
                You can still make a single or monthly donation by calling our
                Supporter Services team on{" "}
                <PhoneNumber>0300 123 1022</PhoneNumber> (8am-6pm).
              </p>
              <p>
                Our work to prevent, diagnose and treat cancer has saved
                millions of lives, but we won’t stop until we’ve beaten cancer
                for good.
              </p>

              <Heading h3>Donate to Stand Up To Cancer</Heading>
              <p>
                Donate by text: To give £30, £20 or £10 text THIRTY, TWENTY or
                TEN to 70404. See{" "}
                <a
                  href="https://www.standuptocancer.org.uk/information/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Stand Up To Cancer terms
                </a>
                .
              </p>
              <p>
                Donate by phone: Call <PhoneNumber>0300 123 4444</PhoneNumber>{" "}
                to make a donation.{" "}
              </p>
              <p>
                100% of your donation goes to Cancer Research UK in support of
                the Stand Up To Cancer campaign.{" "}
                <a
                  href="https://www.standuptocancer.org.uk/about"
                  target="_blank"
                  rel="noreferrer"
                >
                  Find out more about Stand Up To Cancer
                </a>
                .
              </p>

              <Button appearance="tertiary" onClick={(): void => Router.back()}>
                <IconFa faIcon={faChevronLeft} size="17px" />
                Back
              </Button>
            </ExtendedBoxWrapper>
          </MaxWidth>
        </PageContainer>
      </DonationFormWrapper>
    </FormNameContext.Provider>
  );
};

export default ErrorPage;
